// Функции для нормализации данных рецепта полученных из сервера

export const normalizeRecipe = (recipeFromServer: RecipeFromServer): Recipe => {
  return {
    activeTime: recipeFromServer?.activeTime ?? 0,
    totalTime: recipeFromServer?.totalTime ?? 0,
    commentsEnabled: recipeFromServer?.commentsEnabled ?? false,
    images: recipeFromServer.images,
    url: recipeFromServer.link,
    id: recipeFromServer.postId,
    title: recipeFromServer.title,
  };
};

export const normalizeFullRecipe = (recipeFromServer: FullRecipeFromServer): FullRecipe => {
  // на внутренних страницах у рецепта нет свойства link
  const recipeData = normalizeRecipe({
    ...recipeFromServer,
    link: 'unknown',
  });
  recipeData.url = recipeFromServer.url || '';

  // TODO: Check if needed, now is done on render
  // recipeFromServer.breadcrumbs = recipeFromServer.breadcrumbs.map((crumb) => {
  //   if (crumb.own === 'kitchen') {
  //     return {
  //       ...crumb,
  //       title: `${crumb.title} кухня`,
  //     };
  //   }
  //   return crumb;
  // });

  return {
    ...recipeData,
    description: recipeFromServer?.description ?? [],
    parsedInstructions: recipeFromServer?.parsedInstructions ?? [],
    yield: recipeFromServer?.yield ?? 0,
    ingredientsCount: recipeFromServer?.ingredientsCount ?? 0,
    ingredientsSections: recipeFromServer?.ingredientsSections ?? [],
    author: recipeFromServer?.author,
    expert: recipeFromServer?.expert,
    caption: recipeFromServer?.caption ?? '',
    instructions: recipeFromServer?.instructions ?? [],
    breadcrumbs: recipeFromServer?.breadcrumbs ?? [],
    customAuthors: recipeFromServer?.customAuthors ?? '',
  };
};
